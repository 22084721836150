@use "../../common/styles/Variables" as variables;

.footer {
  width: 100%;
  position: relative;
  bottom: 0;
  left: 0;
}

.footerAppBar {
  background-color: variables.$mealzer-color;
  margin-top: 20px;
}

.navBar {
  margin: 15px;
}
