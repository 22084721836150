@use "../../common/styles/Variables" as variables;

.mealChipMargins {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mealChip {
  height: 100%;
  justify-content: center;
  &:hover {
    text-decoration: underline #000000;
  }
}

.mealChipText {
  white-space: normal;
}

.tableCell {
  border-color: variables.$mealzer-color;
  border-width: 3px;
}

.tableCellInteractive {
  &:hover {
    background-color: lightgray;
    cursor: pointer;
  }
}
