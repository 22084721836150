.recipeCardMedia {
  object-fit: cover;
}

.recipeCard {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.recipeCardActionArea {
  flex-grow: 1;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
}

.recipeTitleCard {
  display: flex;
  height: 50%;
}

.recipeName {
  text-overflow: ellipsis;
  width: 100%;
}

.recipeImage {
  object-fit: cover;
  max-width: 25%;
}

.recipeTagChip {
  margin: 3px;
}

.importRecipeOptionButton {
  flex-direction: column;
  margin: 10px;
  width: 95%;
}
