.groceryListAccordion {
  box-shadow: none;
}

.groceryListItemText {
  padding-right: 50px;
}

.alignItems {
  display: flex;
  align-items: center;
}

.alignRecipes {
  @extend .alignItems;
  flex-direction: row-reverse;
}

.recipeAvatar {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
