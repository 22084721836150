@use "./Variables" as variables;

.mainContent {
  min-height: 100vh;
  width: 100%;
}

.centerText {
  text-align: center;
}

.centerDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.absoluteDialog {
  width: 100%;
  position: absolute;
  top: 0;
}

.buttonMargins {
  margin: 5px;
}

.listItem {
  .listItemSecondaryAction {
    visibility: hidden;
  }

  &:hover {
    .listItemSecondaryAction {
      visibility: visible;
    }
  }
}

.Toastify__toast--success {
  border: 1px solid black !important;
}
